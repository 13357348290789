<template>
    <div class="controller" v-if="checkedCamera.camera_type==2">
        <div class="ptz-cell ptz-left" @mousedown="controlDirection('left')" @mouseup="controlDirection('stop')"></div>
        <div class="ptz-cell ptz-right" @mousedown="controlDirection('right')" @mouseup="controlDirection('stop')"></div>
        <div class="ptz-cell ptz-up" @mousedown="controlDirection('up')" @mouseup="controlDirection('stop')"></div>
        <div class="ptz-cell ptz-down" @mousedown="controlDirection('down')" @mouseup="controlDirection('stop')"></div>
        <div class="ptz-cell round ptz-zooout" @mousedown="controlDirection('zoomout')" @mouseup="controlDirection('stop')"></div>
        <div class="ptz-cell round ptz-zoomin" @mousedown="controlDirection('zoomin')" @mouseup="controlDirection('stop')"></div>
    </div>
</template>

<script>
import {cameraAjax} from "@/api/index.js";
export default {
    props:["checkedCamera"],
    methods:{
        controlDirection(statu){
            if(!this.checkedCamera){return}
            if(!this.checkedCamera.movieurls){
                this.$message.error("摄像头信息不全无法查看画面")
                return
            }
            let data = this.checkedCamera.movieurls + 'api/v1/control/ptz?' + 'serial=' + this.checkedCamera.national_num +'&code=' + this.checkedCamera.channel + '&command=' + statu;
            cameraAjax.get(data).then(data => {})
        },
    }
}
</script>

<style lang="scss" scoped>
.controller{
    display:flex;
    justify-content: space-between;
    margin:0 6px 0 12px;
    .ptz-cell{
        width:60px;
        height:60px;
    }
    .round{
        border-radius: 50%;
    }
    .ptz-zoomin{
        background: url("../../../assets/images/Subtractmin.png") no-repeat;
        background-size: cover;
    }
    .ptz-zooout{
        background: url("../../..../../../assets/images/Subtract.png") no-repeat;
        background-size: cover;
    }
    .ptz-left{
        background: url("../../../assets/images/Subtractleft.png") no-repeat;
        background-size: cover;
    }
    .ptz-right{
        background: url("../../../assets/images/Subtractr.png") no-repeat;
        background-size: cover;
    }
    .ptz-up{
        background: url("../../../assets/images/Subtractup.png") no-repeat;
        background-size: cover;
    }
    .ptz-down{
        background: url("../../../assets/images/Subtractd.png") no-repeat;
        background-size: cover;
    }
}
</style>