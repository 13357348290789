<template>
    <div>
        <a-modal
            class="showDetail"
            :title="title"
            :visible.sync="dialogVisible"
            v-if="dialogVisible"
            :closable="false"
            width="1000px"
            :footer="null"
            @close="closeDetail">
            <span v-if="showIcon">
                <span @click="closeDetail">
                    <a-icon type="close-circle" />
                </span>
                <span class="previous-icon icon" @click="setPrevious">
                    <a-icon type="left" />
                </span>
                <span class="next-icon icon" @click="setNext">
                    <a-icon type="right" />
                </span>
            </span>
            <div class="detail-box">
                <a-spin :spinning="loading" tip="加载中...">
                    <div style="width:628px">
                        <div style="position:relative;" v-if="!showScreen && !showRealTime">
                            <Canvas
                                ref="canvas"
                                width="628"
                                height="353"
                                :link="checkedItem.img_list[this.imgIndex]"
                                :id="checkedItem.alarm_id"
                                :info="point"
                                :area="checkedItem.area"
                                :area_new="checkedItem.area_new"
                                :isCanDraw="false"
                                :isShowDrag="isShowDraw"
                            />
                            <div style="position:absolute;top:20px;right:20px;" @click="fullScreen" v-if="checkedItem.device_type_id == 1">
                                <a-icon type="zoom-in" style="color:#fff;font-size:20px;"/>
                            </div>
                        </div>
                        <div class="videotape" v-if="showScreen && !showRealTime">
                            <Playback :videotapeUrl="videotapeUrl" :videoLoading="playloading"></Playback>
                        </div>
                        <div class="video-box" v-if="showRealTime">
                            <CameraScreen ref="cameraScreen" :checkedCamera="cameraData"></CameraScreen>
                        </div>
                        <div class="img_box" v-if="type == 'real' && checkedItem.device_type_id == 1">
                            <a-button :class="['icon-box',atHeadOfList ? 'disable-icon-box':'']" @click.native="changeImgBox('reduce')" :disabled="atHeadOfList">
                                <a-icon type="double-left" />
                            </a-button>
                            <div class="img_list" ref="box">
                                <div class="img_data" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
                                    <img :src="item" class="img" v-for="(item,i) in checkedItem.img_list" :class="imgIndex === i ? 'selectImg' : ''" @click="changeImg(i)"/>
                                </div>
                            </div>
                            <a-button :class="['icon-box',atEndOfList ? 'disable-icon-box':'']" @click.native="changeImgBox('add')" :disabled="atEndOfList">
                                <a-icon type="double-right" />
                            </a-button>
                        </div>
                        <div v-if="showRealTime">
                            <PtzControl :checkedCamera="cameraData"></PtzControl>
                        </div>
                        <div class="playbacktimebox" v-if="showScreen && !showRealTime">
                            <PlaybackTime :checkedCamera='cameraData' :alarmData='checkedItem' @changeload="getload" @getVideoUrl="getVideoUrl"></PlaybackTime>
                        </div>
                        <div class="check-box" v-if="checkedItem.device_type_id == 1 && !showScreen && !showRealTime">
                            <a-checkbox v-model="isShowDraw" style="color:#1890FF;">显示功能区域</a-checkbox>
                        </div>
                    </div>
                    <div class="detail">
                        <div class="button-box" v-if="type == 'accurate' || checkedItem.device_type_id == 1">
                            <div class="button" @click="changeShowC">
                                <img src="../images/playback.png"/>
                                <span>{{buttonTxt}}</span>
                            </div>
                            <div class="button" style="margin-left:30px;" @click="checkScreen">
                                <img src="../images/video.png"/>
                                <span>实时画面</span>
                            </div>
                        </div>
                        <div class="setting-item" v-if="checkedItem.max_people && checkedItem.max_people>0">
                            <span>{{checkedItem.modelname == 'gathering' ? '人员统计：' : '数量统计：'}}</span>
                            <div class="setting-item-r">
                                <span class="notice-type">{{checkedItem.max_people}}</span>
                            </div>
                        </div>
                        <div class="setting-item" v-if="checkedItem.additional_fields">
                            <span>{{checkedItem.additional_fields.key}}:</span>
                            <div class="setting-item-r">
                                <span class="notice-type">{{checkedItem.additional_fields.value}}</span>
                            </div>
                        </div>
                        <div class="setting-item">
                            <span>告警时间:</span>
                            <div class="setting-item-r">
                                <span class="notice-type">{{checkedItem.alarm_time}}</span>
                            </div>
                        </div>
                        <div class="setting-item" v-if="type == 'accurate'">
                            <span>检查功能:</span>
                            <div class="setting-item-r" v-if="checkedItem.label_name.length > 0">
                                <div class="setting-tag notice-unames" v-for="(item,i) in checkedItem.label_name" :key="item.uid" v-if="item">
                                    <span>{{item}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="setting-item" v-if="checkedItem.device_type_id == 1 && type == 'real'">
                            <span>置信度:</span>
                            <div class="setting-item-r">
                                <span class="notice-type">{{((checkedItem.confidence_level)*100).toFixed(0)}}%</span>
                            </div>
                        </div>
                        <div class="setting-item" v-if="type == 'real'">
                            <span>设备地址:</span>
                            <div class="setting-item-r">
                                <span class="notice-type">{{checkedItem.address}}</span>
                            </div>
                        </div>
                        <div class="setting-item">
                            <span>通知方式:</span>
                            <div class="setting-item-r">
                                <span class="notice-type">{{checkedItem.notice_types}}</span>
                            </div>
                        </div>
                        <div class="setting-item flexColumn" v-if="checkedItem.opt_id && type == 'real'">
                            <span>操作人员:</span>
                            <div class="setting-item-b">
                                <div class="setting-tag notice-unames" v-for="(item,i) in checkedItem.opt_id" :key="item.uid" v-if="item">
                                    <span>{{item}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="setting-item flexColumn">
                            <span>通知人员:</span>
                            <div class="setting-item-b">
                                <div class="setting-tag notice-unames" v-for="(item,i) in checkedItem.notice_unames" :key="item.uid" v-if="item">
                                    <span>{{item}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="setting-item flexColumn">
                            <span>抄送人员:</span>
                            <div class="setting-item-b">
                                <div class="setting-tag notice-unames" v-for="(item,i) in checkedItem.cc_people_uids" :key="item.uid" v-if="item">
                                    <span>{{item}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-spin>
            </div>
            <div class="dialog-footer" v-if="checkedItem.status == 0">
                <a-button type="danger" @click="changeStatu(2)">误报</a-button>
                <a-button type="primary" style="margin-left:29px;" @click="changeStatu(1)">处理</a-button>
            </div>
        </a-modal>
        <a-modal
            class="imgDialog"
            :visible.sync="showImg"
            v-if="showImg"
            @click.native="close"
            :closable="false">
            <Canvas
                style="position:absolute;left:50%;transform: translateX(-50%)"
                ref="bigCanvas"
                :width=width
                :height=height
                :link="checkedItem.img_list[this.imgIndex]"
                :id="checkedItem.alarm_id"
                :info="point"
                :area="checkedItem.area"
                :area_new="checkedItem.area_new"
                :isCanDraw="false"
                :isMove='true'
                :isShowDrag="isShowDraw"
                classname='canvasBox'
                @isClose="changeClose"
            />
            <template slot="footer">
                <span class="dialog-footer">
                    <div class="footer" @click="changeClose">
                        <div class="previous-icon" @click="setPrevious" v-if="showIcon">
                            <a-icon type="left" />
                        </div>
                        <div class="next-icon" @click="setNext" v-if="showIcon">
                            <a-icon type="right" />
                        </div>
                        <div class="check-box">
                            <a-checkbox v-model="isShowDraw" @change='changeShow'>显示功能区域</a-checkbox>
                        </div>
                    </div>
                </span>
            </template>
        </a-modal>
    </div>
</template>

<script>
import Canvas from "@/components/canvasImg";
import CameraScreen from "@/components/cameraScreen";
import PtzControl from "./ptzControl";
import Playback from "@/components/playback";
import PlaybackTime from "@/components/playbackTime";
import { mapState } from 'vuex';
import {deviceDetail,setAlarmRecordStatu,alarmRecordDetail} from "@/api/data";
export default {
    props:{
        checkedItem:{
            type:Object
        },
        dialoading:{
            type:Boolean,
            default: false
        },
        type:{
            type:String
        },
        showIcon:{
            type:Boolean,
            default: true
        },
        total:{
            type:Number
        },
        pageSize:{
            type:Number
        },
    },
    components:{Canvas,CameraScreen,Playback,PlaybackTime,PtzControl},
    data(){
        return {
            showImg:false,
            width:'',
            height:'',
            host:'http://aiyunweishi.oos-cn.ctyunapi.cn/',
            dialogVisible:false,
            showPlayback:false,
            PlaybackCamera:'',
            isClose:true,
            isShowDraw:'',
            buttonTxt:'查看回放',
            // 播放
            videotapeUrl: "",
            showNoPicture:false,
            loading:false,
            isFresh:false,
            showScreen:false,
            showRealTime:false,
            cameraData:'',
            imgIndex:-1,
            point:[],
            title:'',
            currentOffset:0,
            paginationFactor:140,
            playloading:false,
            windowSize:3,
        }
    },
    computed: {
        ...mapState('d2admin/user', [
            'info'
        ]),
        atHeadOfList() {
            return this.currentOffset === 0;
        },
        atEndOfList(){
            return this.currentOffset <= (this.paginationFactor * -1) * (this.checkedItem.img_list.length - this.windowSize);
        },
    },
    watch:{
        showScreen(){
            if(this.showScreen){
                this.buttonTxt = '查看图片';
            }else{
                this.buttonTxt = '查看回放'
            }
        },
        dialoading(){
            this.loading = this.dialoading;
        },
    },
    methods:{
        getVideoUrl(url){
            this.videotapeUrl = url;
        },
        getload(val){
            this.playloading = val;
        },
        changeShowC(){
            if(this.cameraData){
                this.showScreen = !this.showScreen;
                this.showRealTime = false;
            }else{
                this.$message.warning('您没有权限观看回放画面')
                return
            }
        },
        checkScreen(){
            // this.showRealTime = true;
            if(this.cameraData){
                this.showRealTime = true;
            }else{
                this.$message.warning('您没有权限观看实时画面')
                return
            }
        },
        show(isShowDraw){
            this.$nextTick(()=>{
                if(this.type === 'real'){
                    this.windowSize = Math.floor(this.$refs.box.offsetWidth / this.paginationFactor);
                    let ararmName = this.checkedItem.alarm_name ? this.checkedItem.alarm_name : this.checkedItem.alarm_type_name;
                    let alarmCategory = this.checkedItem.alarm_category ? this.checkedItem.alarm_category === 'alarm' ? '-实时告警' : '-间隔告警' : '';
                    this.title = ararmName + alarmCategory;
                    if(this.checkedItem.device_type_id === 1){
                        deviceDetail({
                            device_id:194,
                        }).then(res => {
                            if(res.code === 1){
                                this.cameraData = res.data;
                            }
                        })
                    }
                }else{
                    this.title = this.checkedItem.device_name;
                }
                this.imgIndex = this.checkedItem.img_list.length - 1;
                if(this.checkedItem.img_list.length > this.windowSize){
                    this.currentOffset = this.currentOffset - this.paginationFactor;
                }
                this.point = this.checkedItem.point;
            })
            this.showScreen = false;
            this.isShowDraw = isShowDraw;
            this.dialogVisible = true;
            this.isFresh = false;
            document.onkeydown = (e) => {
                //事件对象兼容
                let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
                //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
                if (e1 && e1.keyCode == 37) {
                    this.setPrevious();
                }
                if (e1 && e1.keyCode == 39) {
                    this.setNext();
                } 
            }
        },
        setPrevious(){
            this.showRealTime = false;
            this.showScreen = false;
            this.$emit('previous')
        },
        setNext(){
            this.showRealTime = false;
            this.showScreen = false;
            this.$emit('next')
        },
        changeImgBox(type){
            if(type==='add'){
                this.currentOffset = this.currentOffset - this.paginationFactor;
                this.imgIndex = this.imgIndex+1;
            }else{
                this.currentOffset = this.currentOffset + this.paginationFactor;
                this.imgIndex = this.imgIndex-1;
            }
        },
        changeStatu(type){
            let params = {
                status:type,
                alarm_id:this.checkedItem.alarm_id
            }
            if(this.type === 'real'){
                setAlarmRecordStatu(params).then(data => {
                    if(data.code === 1){
                        this.$message.success('操作成功')
                        this.isFresh = true;
                        this.checkedItem.status = type;
                        alarmRecordDetail({alarm_id:this.checkedItem.alarm_id}).then(res => {
                            if(res.code === 1){
                                this.checkedItem.opt_id = res.data.opt_id;
                            }
                        })
                    }
                })
            }else{
                // this.$api.setManualRecordStatus(params).then(data => {
                //     if(data.code === 1){
                //         this.$message.success({
                //             message:'操作成功'
                //         })
                //         this.isFresh = true;
                //         this.checkedItem.status = type;
                //         // this.$emit('getPageType');
                //     }
                // })
            }
            // this.checkedItem.opt_id = [this.info.name];
        },
        closeDetail(){
            // this.$emit('changeParams',false);
            this.dialogVisible = false;
            this.showRealTime = false;
            this.showScreen = false;
            this.$emit('getPageType');
            if(this.isFresh){
                this.$emit('refresh');
            }
        },
        fullScreen(){
            this.showImg = true;
            window.addEventListener('mousewheel',this.handleScroll,false);
            let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            this.height = height - 100;
            this.width = this.height / 0.75;
        },
        handleScroll (e) {
            var direction = e.deltaY>0?'down':'up'  //该语句可以用来判断滚轮是向上滑动还是向下
            if(direction == 'up'){
                this.width = this.width * 1.1;
                this.height = this.height * 1.1;
            }else{
                this.width = this.width/1.1;
                this.height = this.height/1.1;
            }
        },
        changeClose(){
            this.isClose = false;
        },
        close(){
            if(this.isClose){
                this.showImg = false;
            }else{
                this.isClose = true;
            }
        },
        changeImg(i){
            this.showScreen = false;
            this.showRealTime = false;
            this.imgIndex = i;
            if(this.imgIndex === this.checkedItem.img_list.length - 1){
                this.point = this.checkedItem.point;
            }else{
                this.point = [];
            }
        }
    },
}
</script>

<style lang="scss" scoped>

.setting-item{
    display: flex;
    line-height: 32px;
    font-size: 14px;
    margin-top: 18px;
}
.setting-item>span{
    display: inline-block;
    width: 70px;
    color:#515151;
}
.lookOver{
    color:#1890FF;
    padding-left:14px;
}
.setting-item-r{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 70px);
    align-items: center;
    >span{
        color:#515151;
    }
}
.setting-tag{
    border-radius: 2px;
    line-height: 25px;
    padding:0 9px;
    font-size: 12px;
    color: #7B7B7B;
    background: #E5F2FF;
    letter-spacing: 1px;
    margin-right:20px;
}
.notice-unames{
    margin-top:10px;
}
.notice-type{
    color:rgba(0, 0, 0, .85);
}
.showDetail{
    font-family: Source Han Sans CN;
    z-index: 2001 !important;
    ::v-deep .ant-modal-content{
        height:674px;
        display:flex;
        flex-direction: column;
    }
    ::v-deep .anticon-close-circle{
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        color: #FF4D4F;
    }
    ::v-deep .ant-modal-header{
        border-bottom: 0;
        padding:20px 24px 16px;
        .ant-modal-title{
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
        }
    }
    ::v-deep .ant-modal-body{
        padding:0 21px 0 24px;
        flex:1;
        display: flex;
        flex-direction: column;
        .icon{
            position: absolute;
            top:50%;
            width:28px;
            height:44px;
            background: rgba(0, 0, 0, .5);
            .anticon-left,.anticon-right{
                font-size: 28px;
                line-height: 44px;
                color: #fff;
            }
        }
        .next-icon{
            right:-40px;
        }
        .previous-icon{
            left:-40px;
        }
    }
    .detail-box{
        flex:1;
        .detail{
            flex:1;
            padding-left:30px;    
            overflow-y: auto;
            height: 100%;
            .button-box{
                display: flex;
                padding-bottom: 12px;
            }
            .button{
                display:flex;
                align-items: center;
                border:1px solid #349EFF;
                color:#349EFF;
                padding:0 10px;
                border-radius: 2px;
                font-size: 14px;
                line-height: 30px;
                cursor: pointer;
                >span{
                    margin-left: 6px;
                }
            }
            .flexColumn{
                flex-direction: column;
                .setting-item-b{
                    line-height:25px;
                    display:flex;
                    flex-wrap: wrap;
                }
            }
        }
        .check-box{
            text-align: right;
        }
        ::v-deep .ant-spin-nested-loading{
            height:100%;
            .ant-spin-container{
                height:100%;
                display:flex;
            }
        }

    }
    .dialog-footer{
        margin:20px 0;
        text-align: center;
        ::v-deep .ant-btn{
            width:100px;
            text-align: center;
        }
    }
}
.imgDialog{
    ::v-deep .ant-modal{
        top:0;
        height:100%;
        .ant-modal-content{
            background: transparent;
            box-shadow: none;
            position: static;
        }
        .ant-modal-footer{
            padding: 0;
            position: absolute;
            left: 50%;
            bottom: 48px;
            transform: translateX(-50%);
            border-top:0;
            .footer{
                display:flex;
                align-items: center;
                background: rgba(0, 0, 0, 0.5);
                height:67px;
                .previous-icon,.next-icon {
                    height: 44px;
                    background: rgba(0,0,0,.5);
                    margin: 0 60px;
                    text-align: center;
                    .anticon{
                        font-size: 28px;
                        line-height: 44px;
                        color: #fff;
                        padding: 0 8px;
                    }
                }
                .ant-checkbox-wrapper{
                    width: 136px;
                    text-align: center;
                    line-height:32px;
                }
            }
        }
    }
    .check-box{
        background: #fff;
        border:1px solid #D9D9D9;
        margin:0 24px 0 100px;
        height:32px;
    }
}
.videotape{
    width:628px;
    height:353px;
}
.video-box{
    width:628px;
    height:353px;
}
.img_box{
    margin:8px 0 20px;
    display:flex;
    align-items: center;
    overflow: hidden;
    .icon-box{
        border:0;
        background: transparent;
        box-shadow:none;
        padding:0;
        ::v-deep .anticon-double-left, .anticon-double-right{
            font-size: 20px;
            color:#1890FF;
        }
    }
    .disable-icon-box{
        ::v-deep .anticon-double-left, .anticon-double-right{
            font-size: 20px;
            color:rgba(146, 146, 146, 0.4);
        }
    }
    .img_list{
        flex:1;
        margin:0 12px;
        overflow: hidden;
        .img_data{
            display: flex;
            flex-wrap: nowrap;
            .img{
                width:124px;
                height:70px;
                margin:5px 8px 0;
                box-sizing: border-box;
            }
            .selectImg{
                border:2px solid #1890FF;
            }
        }
    }
}
</style>