<template>
    <div class="alarm">
        <div class="content">
            <div class="header">
                <div class="c-title">
                    告警数据驾驶舱
                </div>
                <a-form-model layout="inline" :model="filters">
                    <div>
                        <a-form-model-item>
                            <a-input-search v-model="keyword" placeholder="搜索设备名称" enter-button @search="search" />
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-select v-model="filters.alarm_type_id" placeholder="请选择告警功能类型" style="width:210px;" :allowClear="true" @change="getAlarm(1)">
                                <a-select-option v-for="item in alarmTypes" :key="item.code">
                                    {{item.alarm_type_name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-select v-model="filters.alarm_category" placeholder="请选择告警类型" style="width:210px;" :allowClear="true" @change="getAlarm(1)">
                                <a-select-option v-for="item in alarmCategoryList" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-select v-model="filters.status" placeholder="请选择状态" style="width:210px;" :allowClear="true" @change="getAlarm(1)">
                                <a-select-option v-for="item in status" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-range-picker 
                                :disabled-date="disabledDate"
                                :show-time="{
                                    hideDisabledOptions: true,
                                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                                }"
                                @change="onchange"
                                @ok="changeDate" />
                        </a-form-model-item>
                    </div>
                    <div class="button-box">
                        <a-checkbox v-model="isShowDraw">显示功能区域</a-checkbox>
                    </div>
                </a-form-model>
            </div>
            <div class="alarm-box">
                <a-empty v-if="tableData.length === 0"/>
                <div class="ant-row" v-else>
                    <a-spin :spinning="spinning" tip="加载中...">
                        <div class="ant-col" v-for="(item,index) in tableData" :key="item.alarm_id">
                            <div class="device-content">
                                <div ref="imgbox" class="imgList" @click="showDetail(item,index)">
                                    <Canvas
                                        :width="width"
                                        :height="height"
                                        lineType="rec"
                                        :link="item.alarm_img"
                                        :info="item.point"
                                        :area='item.area'
                                        :area_new="item.area_new"
                                        :id="item.alarm_id"
                                        :isCanDraw="false"
                                        :isShowDrag="isShowDraw"
                                        @isclick="changeClick"
                                    />
                                </div>
                                <div class="detail">
                                    <div class="device-name">{{item.device_name}}</div>
                                    <div class="info">
                                        <div class="info-time">{{item.alarm_time}}</div>
                                        <div class="info-statu" :class="getColor(item.status)">{{item.status | getStatu}}</div>
                                    </div>
                                    <div class="alarm-type">{{item.alarm_type_name}}{{item.alarm_category ? '-' + item.alarm_category : ''}}</div>
                                </div>
                            </div>
                        </div>
                    </a-spin>
                </div>
                <div class="pagination-box">
                    <a-pagination show-quick-jumper show-size-changer :current="filters.page" :pageSize="filters.pageSize"
                    :total="total" @change="handlePageChange" @showSizeChange="changeSize"/>
                </div>
            </div>
        </div>
        <DataDetail
            ref="detailDialog"
            :checkedItem="checkedItem"
            :dialoading="diaspinning"
            :pageSize="filters.pageSize"
            :total="total"
            @refresh="refresh"
            @previous="getPrevious"
            @next="getNext"
            @getPageType="getPageType"
            type="real"
        />
    </div>
</template>

<script>
import {userList,alarmType,allAlarmList,delAlarm,alarmRecordDetail} from "@/api/data";
import moment from 'moment';
import DataDetail from "./components/dataDetail";
import Canvas from "@/components/canvasImg";
import {changeColor , getStatuTxt , getNoticeUnames} from "@/libs/tools";
export default {
    components:{Canvas,DataDetail},
    data(){
        return {
            deviceTypes: [{name:'摄像头',id:1}],
            alarmTypes: [],
            status: [{name:"未处理",id:0},{name:"已处理",id:1},{name:"误报",id:2}],
            tableData: [],
            total: 0,
            filters:{
                page:1,
                pageSize:10,
                start_time:'',
                end_time:'',
                alarm_type_id:undefined,
                alarm_category:undefined,
                status:undefined,
                search: '',
                search_user:'',
            },
            user:'',
            keyword:'',
            point:'',
            isShowDraw:true,
            isDel:false,
            checkAll:false,
            delItem:[],
            isClick:true,
            checkedItem:{},
            checkId:'',
            checkPage:[],
            index:'',
            showData:true,
            spinning: false,
            changePageType:'data',
            diaspinning:false,
            showDetailCount:1,
            alarmCategoryList:[{name:"实时告警",id:'alarm'},{name:"间隔告警",id:'interval'}],
            type:'',
            disabledDate(current) {
                return current && current > moment().endOf('day');
            },
            width:'',
            height:'',
        }
    },
    filters:{
        getStatu(val){
            return getStatuTxt(val)
        }
    },
    mounted(){
        this.getFilterData();
        this.getUserList();
        this.getAlarm();
        // 注：window.onresize只能在项目内触发1次
        window.onresize = (windowResize) => {
            this.getCanvasSize();
        }
    },
    methods: {
        moment,
        getCanvasSize(){
            this.$nextTick(() => {
                this.width = (this.$refs.imgbox[0].offsetWidth).toString();
                this.height = ((this.$refs.imgbox[0].offsetHeight)).toString();
            })
        },
        getUserList(){
            userList({page:1,pageSize:0,notice_used:true}).then(res => {
                if(res.code === 1){
                    this.userData = res.data.user_list;
                }
            })
        },
        getColor(val){
           return changeColor(val)
        },
        handleSizeChange(val){
            this.filters.pageSize = val;
            this.getAlarm();
        },
        getFilterData(){
            alarmType({status:1}).then(res => {
                if(res.code === 1){
                    this.alarmTypes = res.data;
                }
            })
        },
        refresh(){
            this.getAlarm();
        },
        getAlarm(page){
            if(this.changePageType === 'prevornext'){
                this.diaspinning = true;
            }else{
                this.diaspinning = false;
                this.spinning = true;
            }
            if(page){
                this.filters.page = page
            }
            console.log(this.filters.page);
            allAlarmList(this.filters).then(res => {
                if(res.code === 1){
                    this.tableData = res.data.data.map(item=>{
                        item.point = item.point ? JSON.parse(item.point) : [];
                        item.area = item.area ? JSON.parse(item.area) : [];
                        item.area_new = item.area_new ? JSON.parse(item.area_new) : '';
                        item.alarm_category = item.alarm_category ? (item.alarm_category === 'alarm' ? '实时告警' : '间隔告警') : '';
                        if(this.delItem.indexOf(item.alarm_id) > -1){
                            item.checked = true;
                        }else{
                            item.checked = false;
                        }
                        return item
                    })
                    this.spinning = false;
                    if(this.changePageType === 'prevornext' || this.showDetailCount === 0){
                        this.diaspinning = false;
                        this.checkId = this.tableData[this.index].alarm_id;
                        this.getDetail(this.checkId);
                    }

                    if(this.tableData.length == 0){
                        this.showData = false;
                    }else{
                        this.showData = true;
                        this.getCanvasSize();
                    }
                    if(this.tableData.length == 0 && this.filters.page != 1){
                        this.getAlarm(this.filters.page - 1)
                    }
                    // 判断当前页面是否已经全选 未全选取消全选框选中状态
                    let index = this.checkPage.indexOf(this.filters.page);
                    if(index === -1){
                        this.checkAll = false;
                    }else{
                        this.checkAll = true;
                    }
                    this.total = res.data.total
                }
            })
        },
        handlePageChange(page){
            this.getAlarm(page)
        },
        changeSize(current, pageSize){
            this.filters.pageSize = pageSize;
            this.getAlarm(1);
        },
        onchange(date){
            if(date.length === 0){
                this.filters.start_time = '';
                this.filters.end_time = '';
                this.getAlarm(1);
            }
        },
        changeDate(date){
            this.filters.start_time = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
            this.filters.end_time = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
            this.getAlarm(1);
        },
        search(){
            this.filters.search = this.keyword;
            this.getAlarm(1)
        },
        searchUser(){
            this.filters.search_user = this.user;
            this.getAlarm(1);
        },
        changeClick(val){
            this.isClick = val;
        },
        getPrevious(){
            if(this.index === 0){
                if(this.filters.page != 1){
                    this.changePageType = 'prevornext';
                    this.index = this.filters.pageSize - 1;
                    this.getAlarm(this.filters.page - 1);
                }else{
                    this.$message.warning('当前是第一条数据');
                }
            }else{
                this.index = this.index - 1;
                this.checkId = this.tableData[this.index].alarm_id;
                this.getDetail(this.checkId);
            }
        },
        getNext(){
            let total = this.filters.page * this.filters.pageSize;
            let count = this.tableData.length - 1;
            if(total >= this.total && this.index == count){
                this.$message.warning('当前是最后一条数据');
            }else{
                if(this.index == this.filters.pageSize - 1){
                    this.changePageType = 'prevornext';
                    this.index = 0;
                    this.getAlarm(this.filters.page + 1);
                }else{
                    this.index = this.index + 1;
                    this.checkId = this.tableData[this.index].alarm_id;
                    this.getDetail(this.checkId);
                }
            }
        },
        getPageType(){
            this.changePageType = 'data';
        },
        showDetail(item,index){
            this.index = index;
            if(this.isClick){
                this.getDetail(item.alarm_id);
            }
            this.isClick = true;
        },
        getDetail(id){
            this.showDetailCount ++ ;
            alarmRecordDetail({alarm_id:id}).then(res => {
                if(res.code === 1){
                    this.checkedItem = getNoticeUnames(res.data);;
                    this.$refs.detailDialog.show(this.isShowDraw);
                }
            })
        },
    },
    
}
</script>

<style lang="scss" scoped>
.alarm{
    height:100%;
    display:flex;
    justify-content: space-between;
    background: #F7F9FA;
    font-family: Source Han Sans CN;
    .content{
        flex:1;
        margin-left:5px;
        display: flex;
        flex-direction: column;
        .header{
            flex:1;
            background: #fff;
            padding:6px 30px 0;
            display:flex;
            flex-direction: column;
            justify-content: space-evenly;
            box-sizing: border-box;
            .c-title{
                font-size: 20px;
                line-height: 28px;
                color: rgba(0, 0, 0, 0.85);
            }
            ::v-deep .ant-form{
                display:flex;
                justify-content: space-between;
                .button-box{
                    display:flex;
                    justify-content: right;
                    align-items: center;
                    flex-wrap: wrap;
                    .delbox{
                        margin-right:20px;
                    }
                }
            }
        }
        .alarm-box{
            margin:21px 21px 30px;
            height:77.5%;
            box-sizing: border-box;
            ::v-deep .ant-empty{
                height:calc(100% - 53px);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .ant-row{
                height:calc(100% - 53px);
                ::v-deep .ant-spin-nested-loading{
                    width:100%;
                    height:100%;
                    overflow:hidden;
                    .ant-spin-container{
                        width:100%;
                        height:100%;
                        display: flex;
                        flex-wrap: wrap;
                        overflow-y: auto;
                    }
                }   
                .ant-col{
                    width:20%;
                    min-width: 244px;
                    padding:9px 0.73%;
                    height:48%;
                    box-sizing: border-box;
                    .device-content{
                        width:100%;
                        height:100%;
                        display: flex;
                        flex-direction: column;
                        background: #fff;
                        .imgList{
                            width:100%;
                            height:59.8%;
                        }
                        .detail{
                            flex:1;
                            padding:15px 16px;
                            box-sizing: border-box;
                            .device-name{
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(0, 0, 0, 0.85);
                            }
                            .info{
                                display:flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 14px;
                                line-height: 40px;
                                .info-time{
                                    color:rgba(0, 0, 0, 0.45);
                                }
                                .red{
                                    color:#F6552C;
                                }
                                .green{
                                    color:#42BA7B;
                                }
                                .blue{
                                    color:#1890FF;
                                }
                            }
                            .alarm-type{
                                font-size: 14px;
                                line-height: 22px;
                                color: #1890FF;
                            }
                        }
                    }
                }
            }
            .pagination-box{
                text-align: right;
                margin-top:21px;
            }
        }
    }
}
</style>